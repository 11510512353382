import React from "react";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Formik, FormikConfig, FormikHelpers } from "formik";
import { DialogActions, DialogTitle, Dialog, DialogContent } from "../../../../../../components/Dialog";
import API from '../../../../../../api';
import formatPennies from "../../../../../../helpers/format/formatPennies";
import { openSnackbar } from '../../../../../../modules/ui';
import { useDispatch } from 'react-redux';
import SpinnerButton from '../../../../../../components/buttons/SpinnerButton';

interface Props {
  open: boolean;
  handleClose: (arg: boolean) => void;
  negativeMarginMax: number;
  negativeMarginCount: number;
  carrierPurchaseAmount: number;
  brokerPayableAmount: number;
  fundingRequestId: string;
  amountRequested: number;
  categoryId: string;
  negativeMarginFundForm: FormikConfig<FormValues>;
}

interface FormValues {
  convertToNF: string;
}

export default ({
  open,
  handleClose,
  fundingRequestId,
  negativeMarginMax,
  negativeMarginCount,
  carrierPurchaseAmount,
  brokerPayableAmount,
  amountRequested,
  categoryId,
  negativeMarginFundForm,
}: Props): JSX.Element => {
    const dispatch = useDispatch();
    return (
        <Formik
            {...negativeMarginFundForm}
            initialValues={{
                convertToNF: "fund"
            }}
            onSubmit={async (
                values: FormValues,
                { setSubmitting }: FormikHelpers<FormValues>
            ) => {
                setSubmitting(true);

                try {
                    if (values.convertToNF === "bill_out") {
                        await API.factoring.updateFundingRequest({
                            status: 'non_factored',
                            id: fundingRequestId,
                            send_bill_out_change_email: true
                        }, {});
                        await API.factoring.createLineItem(fundingRequestId, {
                            applied_against: "client",
                            approval_status: "approved",
                            amount_requested: amountRequested,
                            category: categoryId,
                        })
                        dispatch(
                        openSnackbar(
                            "success",
                            "Funding request updated to Non-Factored Bill Out Only due to short payment on receivable. Client has been notified via email."
                        )
                    );
                    }
                } catch (error) {
                    console.error("Error in submitting form: ", error);
                } finally {
                    setSubmitting(false);
                    handleClose(values.convertToNF === "bill_out");
                }
            }}
        >
            {({
                  handleChange,
                  values,
                  isSubmitting,
                  submitForm
              }): JSX.Element => (
                <Dialog onClose={() => handleClose(false)} open={open} fullWidth maxWidth="sm">
                    <DialogTitle onClose={() => handleClose(false)}>Should the Carrier Be Funded?</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Negative Margin Max No. in 30 Days: {negativeMarginMax}
                        </Typography>
                        <Typography>
                            Negative Margin Running Count Last 30 Days: {negativeMarginCount}
                        </Typography>
                        <Grid item>
                            <RadioGroup
                                aria-label="convertToNF"
                                name="convertToNF"
                                value={values.convertToNF}
                                onChange={handleChange}
                                style={{ flexDirection: "column" }}
                            >
                                <FormControlLabel
                                    value="fund"
                                    control={<Radio />}
                                    label={
                                        <div>
                                            <Typography>Fund Carrier On Platform</Typography>
                                            <Typography style={{ paddingLeft: '1.5rem' }}>
                                                Carrier Purchase Amount: {formatPennies(carrierPurchaseAmount)}
                                            </Typography>
                                            <Typography style={{ paddingLeft: '1.5rem' }}>
                                                Client Receivable Amount: {formatPennies(brokerPayableAmount)}
                                            </Typography>
                                        </div>
                                    }
                                />
                                <FormControlLabel
                                    value="bill_out"
                                    control={<Radio />}
                                    label="Convert To Bill Out Only"
                                />
                            </RadioGroup>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                      <SpinnerButton
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={submitForm}
                        disabled={isSubmitting}
                         style={{ backgroundColor: '#ff8300', color: '#fff' }}
                      >
                        Confirm
                      </SpinnerButton>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
}
