import formatPennies from "../../../../helpers/format/formatPennies";
import formatDate from "../../../../helpers/format/formatDate";
import formatStatus from "../../../../helpers/format/formatStatus";

const columns = [
  {
    key: "client_business",
    name: "Client Business",
    filter: "text",
    filter_key: "client_business"
  },
  {
    key: "debtor",
    name: "Debtor",
    filter: "text",
    filter_key: "debtor"
  },
  {
    key: "carrier",
    name: "Carrier",
    filter: "text",
    filter_key: "carrier"
  },
  {
    key: "amount",
    name: "Invoice Amnt.",
    sort: true,
  },
  {
    key: "invoice_number",
    name: "Invoice Number",
    filter: "text",
    filter_key: "invoice_number"
  },
  {
    key: "user_load_number",
    name: "Load Number",
    filter: "text",
    filter_key: "load_number"
  },
  {
    key: "created",
    name: "Requested At",
    sort: true,
    format: formatDate
  },
  {
    key: "assigned_admin.name",
    name: "Transaction Rep",
    filter: "text",
    filter_key: "transaction_rep"
  },
  {
    key: "amount_funded",
    name: "Amount Funded",
    sort: true
  },
  {
    key: "status",
    name: "Status",
    format: formatStatus,
    filter_key: "status",
    filter: "dropdown",
    filter_value: "",
    filter_values: [
      { label: "New (NOA)", value: "new_noa" },
      { label: "New", value: "new" },
      { label: "Fuel Advance", value: "fuel_advance" },
      { label: "Pending", value: "pending" },
      { label: "Pending (NOA)", value: "pending_noa" },
      { label: "Pending Originals", value: "pending_originals" },
      { label: "Document Issue", value: "document_issue" },
      { label: "CF Review", value: "haulpay_review" },
      { label: "Document Review", value: "document_review" },
      { label: "Approved", value: "approved" },
      { label: "Incomplete", value: "incomplete" },
      { label: "Remote Approved", value: "remote_approved" },
      { label: "Hold-Review", value: "hold" },
      { label: "Declined", value: "declined" },
      { label: "Paid", value: "paid" },
      { label: "Deleted", value: "deleted" },
      { label: "New - Fast Track", value: "new_fasttrack" }
    ]
  },
  {
    key: "actions",
    name: "Actions"
  }
];

export default columns;
