const tabs = (newUplodedDoc: any = {}) => [
  {
    label: "All",
    route: "/admin/transactions/",
    value: "",
    permission: "admin-transactions-all:view"
  },
  {
    label: "New (NOA)",
    route: "/admin/transactions/new_noa",
    value: "new_noa",
    permission: "admin-transactions-new_noa:view"
  },
  {
    label: "New",
    route: "/admin/transactions/new",
    value: "new",
    permission: "admin-transactions-new:view"
  },
  {
    label: "Fuel Adv",
    route: "/admin/transactions/fuel_advance",
    value: "fuel_advance",
    permission: "admin-transactions-fuel_advance:view",
    badge: (newUplodedDoc.fuel_advance || []).length
  },
  {
    label: "Non-Factored",
    route: "/admin/transactions/non_factored",
    value: "non_factored",
    permission: "admin-transactions-non_factored:view"
  },
  {
    label: "Pending",
    route: "/admin/transactions/pending",
    value: "pending",
    permission: "admin-transactions-pending:view",
    badge: (newUplodedDoc.pending || []).length
  },
  {
    label: "Pending (NOA)",
    route: "/admin/transactions/pending_noa",
    value: "pending_noa",
    permission: "admin-transactions-pending_noa:view"
  },
  {
    label: "SP",
    route: "/admin/transactions/special_pending",
    value: "special_pending",
    permission: "admin-transactions-special_pending:view"
  },
  {
    label: "Pending Originals",
    route: "/admin/transactions/pending_originals",
    value: "pending_originals",
    permission: "admin-transactions-pending_originals:view"
  },
  {
    label: "HR",
    route: "/admin/transactions/hold",
    value: "hold",
    permission: "admin-transactions-hold_review:view"
  },
  {
    label: "PD",
    route: "/admin/transactions/pending_delivery",
    value: "pending_delivery",
    permission: "admin-transactions-pending_delivery:view"
  },
  {
    label: "Doc Issue",
    route: "/admin/transactions/document_issue",
    value: "document_issue",
    permission: "admin-transactions-doc_issue:view",
    badge: (newUplodedDoc.document_issue || []).length
  },
  {
    label: "HP Review",
    route: "/admin/transactions/cf_review",
    value: "haulpay_review",
    permission: "admin-transactions-cf_review:view"
  },
  {
    label: "Incomplete",
    route: "/admin/transactions/incomplete",
    value: "incomplete",
    permission: "admin-transactions-incomplete:view"
  },
  {
    label: "Doc Review",
    route: "/admin/transactions/document_review",
    value: "document_review",
    permission: "admin-transactions-document_review:view"
  },
  {
    label: "Approved",
    route: "/admin/transactions/approved",
    value: "approved",
    permission: "admin-transactions-approved:view"
  },
  {
    label: "Remote Approved",
    route: "/admin/transactions/remote_approved",
    value: "remote_approved",
    permission: "admin-transactions-remote_approved:view"
  },
  {
    label: "Declined",
    route: "/admin/transactions/declined",
    value: "declined",
    permission: "admin-transactions-declined:view"
  },
  {
    label: "Paid",
    route: "/admin/transactions/paid",
    value: "paid",
    permission: "admin-transactions-paid:view"
  }
];

export default tabs;
